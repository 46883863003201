import React, { useState, useContext, useEffect } from 'react'
import { Box, Grid } from '@material-ui/core'
import { openedDrawerStyles } from './Styles'
import ExpandedHangingFoldersContainer from '../HangingFolder/ExpandedHangingFoldersContainer'
import Navigation from '../Other/Navigation'
import { ARCHIVE_UI_GLOBAL_STYLES } from '../GlobalStyles'
import { animated, useSpring } from 'react-spring'
import Backdrop from '../Other/Backdrop'
import EmptyState from '../Other/EmptyState'
import HangingFolderIcon from '../../assets/icons/HangingFolderIcon'
import { ConfigContext } from '../Other/Context'
import SpinLoader from '../Other/SpinLoader'
import ReactGA from 'react-ga'

const OpenedDrawer = (props) => {
  const {
    drawer,
    beforeDrawerClose,
    onDrawerClose,
    afterDrawerClose,
    animate,
    parentElementWidth,
    parentElementHeight,
  } = props
  const [isHangingFolderExpanded, setIsHangingFolderExpanded] = useState(false)
  const [hideHangingFolders, setHideHangingFolders] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const classes = openedDrawerStyles(props)
  const config = useContext(ConfigContext)
  const drawerConfig = config && config.drawer
  const hangingFolderConfig = config && config.hangingFolder

  useEffect(() => {
    if (drawer) {
      setLoaded(true)
    }
  }, [drawer])

  const drawerContentAnimation = useSpring({
    from: { top: parentElementHeight },
    to: { top: 0 },
    reverse: hideHangingFolders,
  })

  const animatedNavigation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      tension: 150,
    },
    reverse: isHangingFolderExpanded,
  })

  function afterHangingFolderOpen(event, hangingFolder) {
    setIsHangingFolderExpanded(true)

    ReactGA.event({
      category: 'Hanging Folder',
      action: 'Opened',
      label: hangingFolder && hangingFolder.label,
    })
  }

  function afterHangingFolderClose(event, hangingFolder) {
    setIsHangingFolderExpanded(false)

    ReactGA.event({
      category: 'Hanging Folder',
      action: 'Closed',
      label: hangingFolder && hangingFolder.label,
    })
  }

  function onCloseDrawer(event) {
    if (animate) {
      setHideHangingFolders(true)
      setTimeout(() => {
        beforeDrawerClose && beforeDrawerClose(event, drawer)
        onDrawerClose && onDrawerClose(event, drawer)
        afterDrawerClose && afterDrawerClose(event, drawer)
      }, 300)
    } else {
      beforeDrawerClose && beforeDrawerClose(event, drawer)
      onDrawerClose && onDrawerClose(event, drawer)
      afterDrawerClose && afterDrawerClose(event, drawer)
    }
  }

  function renderContent() {
    if (drawer) {
      if (drawer.hangingFolders && drawer.hangingFolders.length === 0) {
        return (
          <Box className={classes.emptyStateWrapper}>
            <EmptyState
              title={'There are no hanging folders in this drawer'}
              description={'You need to create a hanging folder first'}
              icon={<HangingFolderIcon width={50} height={50} color={'gray'} />}
              actionButtonLabel={'New hanging folder'}
              actionButtonOnClick={hangingFolderConfig && hangingFolderConfig.onCreate}
            />
          </Box>
        )
      }
      return (
        <ExpandedHangingFoldersContainer
          afterHangingFolderOpen={afterHangingFolderOpen}
          afterHangingFolderClose={afterHangingFolderClose}
          hangingFolders={drawer && drawer.hangingFolders}
          drawerLabel={drawer && drawer.label}
          parentElementWidth={parentElementWidth}
          parentElementHeight={parentElementHeight}
        />
      )
    }
  }

  return (
    <Grid item classes={{ root: classes.gridItem }}>
      <Box className={classes.root}>
        {!loaded && <SpinLoader />}
        <animated.div style={drawerContentAnimation} className={classes.drawerContent}>
          <Box className={classes.hangingFolderContainerWrapper}>{loaded && renderContent()}</Box>
        </animated.div>
        {!isHangingFolderExpanded && (
          <Box className={classes.navigation}>
            <animated.div style={animatedNavigation}>
              <Navigation
                classes={{ root: classes.navigationRoot, button: classes.navigationButton }}
                label={`close ${drawer && drawer.label ? drawer.label : 'drawer'}`}
                onClick={onCloseDrawer}
              />
            </animated.div>
          </Box>
        )}
      </Box>
      <Backdrop classes={{ root: classes.backdrop }} />
    </Grid>
  )
}

OpenedDrawer.defaultProps = {
  animate: false,
  parentElementWidth: ARCHIVE_UI_GLOBAL_STYLES.width,
  parentElementHeight: ARCHIVE_UI_GLOBAL_STYLES.height,
}

export default OpenedDrawer
