import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { fade } from '../../constants/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100, //required to be overidden in order to work
    backgroundColor: fade(theme.palette.common.black, 0.6),
  },
}))

const Backdrop = (props) => {
  const { onClick } = props
  const classes = useStyles(props)

  function onBackdropClick(event) {
    event.stopPropagation()
    onClick && onClick(event)
  }

  return <Box onClick={onBackdropClick} className={classes.root} />
}

export default Backdrop
