import React from 'react'
import ClosedFile from './ClosedFile'
import { fileContainerStyles } from './Styles'
import { Box } from '@material-ui/core'

const FilesContainer = (props) => {
  const { files, ...otherProps } = props
  const classes = fileContainerStyles(props)

  return (
    <Box className={classes.root}>
      <Box className={classes.filesWrapper}>
        {files &&
          files.length > 0 &&
          files.map((file, fileIndex) => {
            return <ClosedFile index={fileIndex} key={`file-${fileIndex}`} file={file} {...otherProps} />
          })}
      </Box>
    </Box>
  )
}

FilesContainer.defaultProps = {}

export default FilesContainer
