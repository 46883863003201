import React from 'react'
import { animated, useSpring } from 'react-spring'
import { makeStyles } from '@material-ui/core'
import { fade } from '../../constants/colors'

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.9),
    },
  },
  colorSecondary: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.9),
    },
  },
}))

const DropdownMenu = (props) => {
  const { CustomComponent } = props
  const classes = useStyles(props)

  const animatedDropdownButton = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  })

  function onClicked(event) {
    event.stopPropagation()
  }

  return (
    <animated.div style={animatedDropdownButton} onClick={onClicked}>
      {/* <IconButton
        color={'primary'}
        classes={{
          colorPrimary: classes.colorPrimary,
          colorSecondary: classes.colorSecondary,
        }}
        aria-label="more"
        onClick={onClicked}
        {...IconButtonProps}
      >
        <MoreVertIcon />
      </IconButton> */}
      <CustomComponent />
    </animated.div>
  )
}

DropdownMenu.defaultProps = {}

export default DropdownMenu
