import React, { useState } from 'react'
import ArchiveScene from './ArchiveScene'
import { MuiThemeProvider, jssPreset } from '@material-ui/core'

import theme from './constants/theme'
import globalStyles from './constants/globalStyles'
import { JssProvider } from 'react-jss'
import { create } from 'jss'
import ReactGA from 'react-ga'

const jss = create({ ...jssPreset() })
jss.createStyleSheet(globalStyles).attach()

function App() {
  const [gaInitialized, setGaInitialized] = useState(false)

  if (!gaInitialized) {
    initializeGA()
  }

  function initializeGA() {
    ReactGA.initialize('UA-145844385-2')
    ReactGA.pageview(window.location.pathname + window.location.search)
    setGaInitialized(true)
  }

  return (
    <JssProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        <ArchiveScene />
      </MuiThemeProvider>
    </JssProvider>
  )
}

export default App
