import React from 'react'
import hangingFolderIconBlack from './svg/hanging_folder_black.svg'
import hangingFolderIconGray from './svg/hanging_folder_gray.svg'
import hangingFolderIconWhite from './svg/hanging_folder_white.svg'

const HangingFolderIcon = (props) => {
  const { width, height, color, ...otherProps } = props

  const iconSrc = getIconSrc()

  function getIconSrc() {
    switch (color) {
      case 'gray':
        return hangingFolderIconGray
      case 'white':
        return hangingFolderIconWhite
      default:
        return hangingFolderIconBlack
    }
  }

  return <img width={width} height={height} {...otherProps} src={iconSrc} alt={'Hanging Folder Icon'} />
}

HangingFolderIcon.defaultProps = {
  width: 25,
  height: 25,
  color: 'black',
}

export default HangingFolderIcon
