export const mockedCabinets = [
  {
    id: 1,
    label: 'Home',
    drawers: [
      {
        id: 1,
        label: 'Bills',
        hangingFolders: [
          {
            id: 1,
            label: 'Electricity',
            color: '#055C9D',
            files: [
              {
                id: 1,
                label: 'Year 2020',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 2,
                label: 'Year 2019',
                documents: [
                  {
                    id: 1,
                    label: 'January 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2019',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 3,
                label: 'Year 2018',
                documents: [
                  {
                    id: 1,
                    label: 'January 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2018',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 4,
                label: 'Year 2017',
                documents: [
                  {
                    id: 1,
                    label: 'January 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2017',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 5,
                label: 'Year 2016',
                documents: [
                  {
                    id: 1,
                    label: 'January 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2016',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 6,
                label: 'Year 2015',
                documents: [
                  {
                    id: 1,
                    label: 'January 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2015',
                    fileFormat: 'pdf',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: 'Heating',
            color: '#0E86D4',
            files: [
              {
                id: 1,
                label: 'Year 2020',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 2,
                label: 'Year 2019',
                documents: [
                  {
                    id: 1,
                    label: 'January 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2019',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 3,
                label: 'Year 2018',
                documents: [
                  {
                    id: 1,
                    label: 'January 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2018',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 4,
                label: 'Year 2017',
                documents: [
                  {
                    id: 1,
                    label: 'January 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2017',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 5,
                label: 'Year 2016',
                documents: [
                  {
                    id: 1,
                    label: 'January 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2016',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 6,
                label: 'Year 2015',
                documents: [
                  {
                    id: 1,
                    label: 'January 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2015',
                    fileFormat: 'pdf',
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            label: 'Internet & Cable',
            color: '#68BBE3',
            files: [
              {
                id: 1,
                label: 'Year 2020',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 2,
                label: 'Year 2019',
                documents: [
                  {
                    id: 1,
                    label: 'January 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2019',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 3,
                label: 'Year 2018',
                documents: [
                  {
                    id: 1,
                    label: 'January 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2018',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 4,
                label: 'Year 2017',
                documents: [
                  {
                    id: 1,
                    label: 'January 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2017',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 5,
                label: 'Year 2016',
                documents: [
                  {
                    id: 1,
                    label: 'January 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2016',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 6,
                label: 'Year 2015',
                documents: [
                  {
                    id: 1,
                    label: 'January 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2015',
                    fileFormat: 'pdf',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 2,
        label: 'Receipts',
        hangingFolders: [
          {
            id: 1,
            label: 'Groceries',
            color: '#005746',
            files: [
              {
                id: 1,
                label: 'Target',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 2,
                label: 'Walmart',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 3,
                label: 'Kroger',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: 'Clothing',
            color: '#006D5B',
            files: [
              {
                id: 1,
                label: 'Adidas',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 2,
                label: 'Zara',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 3,
                label: 'Boss',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            label: 'Electronics & Home Appliances',
            color: '#8E4B65',
            files: [
              {
                id: 1,
                label: 'Best Buy',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 2,
                label: 'Amazon',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 3,
                label: 'Apple',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            label: 'Travel',
            color: '#763550',
            files: [
              {
                id: 1,
                label: 'American Airlines',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 2,
                label: 'Booking',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 3,
                label: 'Hotels',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        label: 'Insurance',
        hangingFolders: [
          {
            id: 1,
            label: 'Automobile',
            color: '#9E3A14',
            files: [
              {
                id: 1,
                label: 'Year 2020',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 2,
                label: 'Year 2019',
                documents: [
                  {
                    id: 1,
                    label: 'January 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2019',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 3,
                label: 'Year 2018',
                documents: [
                  {
                    id: 1,
                    label: 'January 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2018',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 4,
                label: 'Year 2017',
                documents: [
                  {
                    id: 1,
                    label: 'January 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2017',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 5,
                label: 'Year 2016',
                documents: [
                  {
                    id: 1,
                    label: 'January 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2016',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 6,
                label: 'Year 2015',
                documents: [
                  {
                    id: 1,
                    label: 'January 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2015',
                    fileFormat: 'pdf',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: 'House & Land',
            color: '#E66912',
            files: [
              {
                id: 1,
                label: 'Year 2020',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 2,
                label: 'Year 2019',
                documents: [
                  {
                    id: 1,
                    label: 'January 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2019',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 3,
                label: 'Year 2018',
                documents: [
                  {
                    id: 1,
                    label: 'January 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2018',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 4,
                label: 'Year 2017',
                documents: [
                  {
                    id: 1,
                    label: 'January 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2017',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 5,
                label: 'Year 2016',
                documents: [
                  {
                    id: 1,
                    label: 'January 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2016',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 6,
                label: 'Year 2015',
                documents: [
                  {
                    id: 1,
                    label: 'January 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2015',
                    fileFormat: 'pdf',
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            label: 'Health & Wellbeing',
            color: '#016367',
            files: [
              {
                id: 1,
                label: 'Year 2020',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2020',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2020',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 2,
                label: 'Year 2019',
                documents: [
                  {
                    id: 1,
                    label: 'January 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2019',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2019',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 3,
                label: 'Year 2018',
                documents: [
                  {
                    id: 1,
                    label: 'January 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2018',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2018',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 4,
                label: 'Year 2017',
                documents: [
                  {
                    id: 1,
                    label: 'January 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2017',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2017',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 5,
                label: 'Year 2016',
                documents: [
                  {
                    id: 1,
                    label: 'January 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2016',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2016',
                    fileFormat: 'pdf',
                  },
                ],
              },
              {
                id: 6,
                label: 'Year 2015',
                documents: [
                  {
                    id: 1,
                    label: 'January 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'February 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'March 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'April 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'May 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'June 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'July 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'August 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'September 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'October 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'November 2015',
                    fileFormat: 'pdf',
                  },
                  {
                    id: 1,
                    label: 'December 2015',
                    fileFormat: 'pdf',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    label: 'Work',
    drawers: [
      {
        id: 1,
        label: 'Accounts Payable',
        hangingFolders: [
          {
            id: 1,
            label: 'Auto Owners',
            color: '#138B83',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
          {
            id: 2,
            label: 'Vendors',
            color: '#00C4B0',
            files: [
              {
                id: 1,
                label: 'A&R Cleaning Industries',
                documents: [
                  {
                    id: 1,
                    label: 'A&R Cleaning Industries',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'A. Dean Watkins-East Inc.',
                documents: [
                  {
                    id: 1,
                    label: 'A. Dean Watkins-East Inc.',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 1,
                    label: 'A. Dean Watkins-East Inc.',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'ACS Foundation',
                documents: [
                  {
                    id: 1,
                    label: 'ACS Foundation',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 4,
                label: 'Adobe',
                documents: [
                  {
                    id: 1,
                    label: 'Acrobat',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 5,
                label: 'Advantage Promotional Services',
                documents: [
                  {
                    id: 1,
                    label: 'Advantage Promotional Services',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Advantage Promotional Services',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Advantage Promotional Services',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Advantage Promotional Services',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 6,
                label: 'Alaiedon Township',
                documents: [
                  {
                    id: 1,
                    label: 'Alaiedon Township',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Alaiedon Township',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Alaiedon Township',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            label: 'Employees',
            color: '#00EAD9',
            files: [
              {
                id: 1,
                label: 'Mladen',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 1,
                label: 'George',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 1,
                label: 'Viktor',
                documents: [
                  {
                    id: 1,
                    label: 'January 2020',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'February 2020',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'March 2020',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            label: 'Rents',
            color: '#345351',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
          {
            id: 5,
            label: 'Internet services',
            color: '#E36920',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
          {
            id: 6,
            label: 'Tools',
            color: '#EFA710',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
          {
            id: 7,
            label: 'Gear',
            color: '#DCAD90',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
          {
            id: 7,
            label: 'Subscriptions',
            color: '#E8D4C0',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
        ],
      },
      {
        id: 2,
        label: 'Insurance companies',
        hangingFolders: [
          {
            id: 1,
            label: 'Hanging Folder 1',
            color: '#DFB160',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
          {
            id: 2,
            label: 'Hanging Folder 2',
            color: '#F9D876',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [],
              },
              {
                id: 2,
                label: 'File 1',
                documents: [],
              },
              {
                id: 3,
                label: 'File 3',
                documents: [],
              },
            ],
          },
          {
            id: 3,
            label: 'Hanging Folder 3',
            color: '#C5E5A5',
            files: [],
          },
          {
            id: 4,
            label: 'Hanging Folder 4',
            color: '#98B66E',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [],
              },
              {
                id: 2,
                label: 'File 1',
                documents: [],
              },
              {
                id: 3,
                label: 'File 3',
                documents: [],
              },
            ],
          },
          {
            id: 5,
            label: 'Hanging Folder 1',
            color: '#BBCBBE',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
          {
            id: 6,
            label: 'Hanging Folder 2',
            color: '#16796F',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [],
              },
              {
                id: 2,
                label: 'File 1',
                documents: [],
              },
              {
                id: 3,
                label: 'File 3',
                documents: [],
              },
            ],
          },
          {
            id: 7,
            label: 'Hanging Folder 3',
            color: '#10564F',
            files: [],
          },
          {
            id: 8,
            label: 'Hanging Folder 4',
            color: '#04425B',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [],
              },
              {
                id: 2,
                label: 'File 1',
                documents: [],
              },
              {
                id: 3,
                label: 'File 3',
                documents: [],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        label: 'Providers',
        hangingFolders: [
          {
            id: 1,
            label: 'Hanging Folder 1',
            color: '#67676F',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
          {
            id: 2,
            label: 'Hanging Folder 2',
            color: '#A97061',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [],
              },
              {
                id: 2,
                label: 'File 1',
                documents: [],
              },
              {
                id: 3,
                label: 'File 3',
                documents: [],
              },
            ],
          },
          {
            id: 3,
            label: 'Hanging Folder 3',
            color: '#EFC2A8',
            files: [],
          },
          {
            id: 4,
            label: 'Hanging Folder 4',
            color: '#D18D96',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [],
              },
              {
                id: 2,
                label: 'File 1',
                documents: [],
              },
              {
                id: 3,
                label: 'File 3',
                documents: [],
              },
            ],
          },
          {
            id: 5,
            label: 'Hanging Folder 1',
            color: '#DFB160',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
          {
            id: 6,
            label: 'Hanging Folder 2',
            color: '#b2bec3',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [],
              },
              {
                id: 2,
                label: 'File 1',
                documents: [],
              },
              {
                id: 3,
                label: 'File 3',
                documents: [],
              },
            ],
          },
          {
            id: 7,
            label: 'Hanging Folder 3',
            color: '#34586E',
            files: [],
          },
          {
            id: 8,
            label: 'Hanging Folder 4',
            color: '#6E3562',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [],
              },
              {
                id: 2,
                label: 'File 1',
                documents: [],
              },
              {
                id: 3,
                label: 'File 3',
                documents: [],
              },
            ],
          },
          {
            id: 9,
            label: 'Hanging Folder 1',
            color: '#C2B2F9',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
        ],
      },
      {
        id: 4,
        label: 'Patients',
        hangingFolders: [
          {
            id: 1,
            label: 'Hanging Folder 1',
            color: '#60668E',
            files: [
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
              {
                id: 1,
                label: 'File 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'File 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'File 3',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    label: 'Family',
    drawers: [
      {
        id: 1,
        label: 'Important Documentation',
        hangingFolders: [
          {
            id: 1,
            label: 'Birth Certificates',
            color: '#40502B',
            files: [
              {
                id: 1,
                label: 'John Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'Maria Gunnarsson Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'Alex Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 4,
                label: 'Anna Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 5,
                label: 'Helena Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: 'Passport & Travel Documents',
            color: '#665A81',
            files: [
              {
                id: 1,
                label: 'John Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'Maria Gunnarsson Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'Alex Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 4,
                label: 'Anna Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 5,
                label: 'Helena Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 2,
        label: 'Licenses & Permits',
        hangingFolders: [
          {
            id: 1,
            label: 'Driving Licences',
            color: '#6699CC',
            files: [
              {
                id: 1,
                label: 'John Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'Maria Gunnarsson Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'Alex Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 4,
                label: 'Anna Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 5,
                label: 'Helena Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: 'Gun Possesion Licences',
            color: '#AC915D',
            files: [
              {
                id: 1,
                label: 'John Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        label: 'Awards & Achievement',
        hangingFolders: [
          {
            id: 1,
            label: 'Dance',
            color: '#B53E47',
            files: [
              {
                id: 1,
                label: 'Maria Gunnarsson Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'Anna Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'Helena Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: 'Military Service',
            color: '#004E5D',
            files: [
              {
                id: 1,
                label: 'John Parker',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 4,
    label: 'Education',
    drawers: [
      {
        id: 1,
        label: 'John Parker',
        hangingFolders: [
          {
            id: 1,
            label: 'University of Sheffield',
            files: [
              {
                id: 1,
                label: 'Bsc',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'Masters',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'PhD',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: 'High School',
            files: [
              {
                id: 1,
                label: 'Year 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'Year 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'Year 3',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 4,
                label: 'Year 4',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 5,
                label: 'Year 5',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 2,
        label: 'Maria Gunnarsson Parker',
        hangingFolders: [
          {
            id: 1,
            label: 'Karolinska Institute',
            files: [
              {
                id: 1,
                label: 'Bsc',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'Masters',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'Doctoral studies',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 4,
                label: 'Certificates',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 5,
                label: 'Work while studies',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: 'High School',
            files: [
              {
                id: 1,
                label: 'All years',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        label: 'Helena Parker',
        hangingFolders: [
          {
            id: 1,
            label: 'Hight School',
            files: [
              {
                id: 1,
                label: 'Year 3',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 2,
                label: 'Year 2',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
              {
                id: 3,
                label: 'Year 1',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: 'Elementary School',
            files: [
              {
                id: 1,
                label: 'Grades',
                documents: [
                  {
                    id: 1,
                    label: 'Document 1',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 2,
                    label: 'Document 2',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 3,
                    label: 'Document 3',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                  {
                    id: 4,
                    label: 'Document 4',
                    fileFormat: 'pdf',
                    createdAt: '04/10/2020',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 5,
    label: 'Other',
    drawers: [
      {
        id: 1,
        label: 'John Parker',
        hangingFolders: [
          {
            id: 1,
            label: 'Hanging Folder 1',
            files: [
              {
                id: 1,
                label: 'Bsc',
                documents: [],
              },
            ],
          },
          {
            id: 2,
            label: 'Hanging Folder 2',
            files: [],
          },
        ],
      },
      {
        id: 2,
        label: 'Empty drawer',
        hangingFolders: [],
      },
    ],
  },
]

export const mockedDrawers = mockedCabinets[0].drawers
export const mockedHangingFolders = mockedDrawers[0].hangingFolders
export const mockedFiles = mockedHangingFolders[0].files
export const mockedDocuments = mockedFiles[0].documents
