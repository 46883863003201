import React, { useRef } from 'react'
import { archiveUIStyles } from './Styles'
import ArchiveUIContainer from './ArchiveUIContainer'
import { useWindowDimensions, useElementDimensions } from '../../hooks'
import { ConfigContext } from '../Other/Context'

const ArchiveUI = (props) => {
  const { config, ...otherProps } = props
  const classes = archiveUIStyles(props)
  const ref = useRef()
  const { viewportHeight } = useWindowDimensions()
  const { elementHeight } = useElementDimensions(ref, [viewportHeight])

  return (
    <div ref={ref} className={classes.root}>
      <ConfigContext.Provider value={config}>
        <ArchiveUIContainer parentElementHeight={elementHeight} {...otherProps} />
      </ConfigContext.Provider>
    </div>
  )
}

ArchiveUI.defaultProps = {
  config: {
    cabinet: {
      dropdownActions: [],
      onCreate: () => {},
    },
    drawer: {
      dropdownActions: [],
      onCreate: () => {},
    },
    hangingFolder: {
      dropdownActions: [],
      onCreate: () => {},
    },
    file: {
      dropdownActions: [],
      onCreate: () => {},
    },
    document: {
      onBeforeClick: () => {},
      onClick: () => {},
      onAfterClick: () => {},
      onCreate: () => {},
    },
  },
}

export default ArchiveUI
