import React, { useRef } from 'react'
import { closedFileStyles } from './Styles'
import colors from '../../constants/colors'
import { Box, Typography } from '@material-ui/core'
import Icon from '../Other/Icon'
import FolderIcon from '../../assets/icons/FolderIcon'
import { useElementDimensions } from '../../hooks'
import { DEFAULT_VIEWPORT_WIDTH, DEFAULT_VIEWPORT_HEIGHT } from '../GlobalStyles'

const ClosedFile = (props) => {
  const {
    file,
    onBeforeFileClicked,
    onFileClicked,
    onAfterFileClicked,
    parentElementWidth,
    parentElementHeight,
  } = props
  const { label, documents = [] } = file
  const classes = closedFileStyles(props)
  const ref = useRef()
  const { elementWidth } = useElementDimensions(ref, [parentElementWidth])
  const labelMaxWidth = elementWidth - 100

  function onClick(event) {
    onBeforeFileClicked && onBeforeFileClicked(event, file)
    onFileClicked && onFileClicked(event, file)
    onAfterFileClicked && onAfterFileClicked(event, file)
  }

  function getCountLabel() {
    if (documents.length === 1) {
      return documents.length + ' document'
    } else {
      return documents.length + ' documents'
    }
  }

  return (
    <Box>
      <Box className={classes.component} onClick={onClick}>
        <div ref={ref} className={classes.fileWrapper}>
          <Box className={classes.fileTag}>
            <Box className={classes.labelIconWrapper}>
              <Icon style={{ marginRight: 8 }}>
                <FolderIcon width={20} height={20} />
              </Icon>
              <Typography style={{ maxWidth: labelMaxWidth }} className={classes.label}>
                {label}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.file}>
            <Box className={classes.header}>
              <Typography variant={'subtitle1'} className={classes.documentsCountLabel}>
                {getCountLabel()}
              </Typography>
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  )
}

ClosedFile.defaultProps = {
  index: 0,
  file: {
    label: 'file',
    color: colors.file,
    documents: [],
  },
  parentElementWidth: DEFAULT_VIEWPORT_WIDTH,
  parentElementHeight: DEFAULT_VIEWPORT_HEIGHT,
}

export default ClosedFile
