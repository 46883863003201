import React, { useState, useRef } from 'react'
import { Box } from '@material-ui/core'
import Cabinet from '.'
import { cabinetsContainerStyles } from './Styles'
import Carousel from '../Other/Carousel'
import { DEFAULT_VIEWPORT_HEIGHT, DEFAULT_VIEWPORT_WIDTH } from '../GlobalStyles'
import { useElementDimensions } from '../../hooks'

const CabinetsContainer = (props) => {
  const { cabinets, parentElementWidth, parentElementHeight } = props
  const classes = cabinetsContainerStyles(props)
  const [isAnyDrawerOpen, setisAnyDrawerOpen] = useState(false)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const ref = useRef()
  const { elementWidth, elementHeight } = useElementDimensions(ref)

  function afterDrawerOpened() {
    setisAnyDrawerOpen(true)
  }

  function afterDrawerClosed() {
    setisAnyDrawerOpen(false)
  }

  function afterSlideChanged(currentIndex) {
    setCurrentSlideIndex(currentIndex)
  }

  return (
    <div ref={ref} className={classes.root}>
      <Carousel disable={isAnyDrawerOpen} showNavigation={!isAnyDrawerOpen} afterSlideChanged={afterSlideChanged}>
        {cabinets &&
          cabinets.length > 0 &&
          cabinets.map((cabinet, cabinetIndex) => {
            return (
              <Box className={classes.cabinetsContainer} key={`cabinet-${cabinet.id}`}>
                <Cabinet
                  classes={{ root: classes.cabinet }}
                  parentElementWidth={elementWidth || parentElementWidth}
                  parentElementHeight={elementHeight || parentElementHeight}
                  label={cabinet.label}
                  afterDrawerOpened={afterDrawerOpened}
                  afterDrawerClosed={afterDrawerClosed}
                  drawers={cabinet.drawers}
                  isOpen={currentSlideIndex === cabinetIndex}
                />
              </Box>
            )
          })}
      </Carousel>
    </div>
  )
}

CabinetsContainer.defaultProps = {
  parentElementHeight: DEFAULT_VIEWPORT_HEIGHT,
  parentElementWidth: DEFAULT_VIEWPORT_WIDTH,
}

export default CabinetsContainer
