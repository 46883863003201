import React, { useRef, useContext } from 'react'
import classNames from 'classnames'
import { Box, Paper, Grid, Typography } from '@material-ui/core'
import { closedDrawerStyles } from './Styles'
import CollapsedHangingFoldersContainer from '../HangingFolder/CollapsedHangingFoldersContainer'
import colors from '../../constants/colors'
import { ARCHIVE_UI_GLOBAL_STYLES, DRAWER_GLOBAL_STYLES } from '../GlobalStyles'
import { animated, useSpring } from 'react-spring'
import DropdownMenu from '../Other/DropdownMenu'
import { ConfigContext } from '../Other/Context'
import { useElementDimensions } from '../../hooks'

const ClosedDrawer = (props) => {
  const {
    drawer,
    hideHangingFolders,
    containerYScroll,

    beforeDrawerOpen,
    onDrawerOpen,
    afterDrawerOpen,
    parentElementWidth,
    parentElementHeight,
  } = props
  const { label, hangingFolders } = drawer
  const classes = closedDrawerStyles(props)
  const doorRef = useRef()
  const config = useContext(ConfigContext)
  const drawerConfig = config && config.drawer
  const { elementWidth } = useElementDimensions(doorRef, [parentElementWidth])
  const labelMaxWidth =
    DRAWER_GLOBAL_STYLES.minLabelWidth > elementWidth - 120 ? DRAWER_GLOBAL_STYLES.minLabelWidth : elementWidth - 120

  const animatedHangingFolderContainerWrapper = useSpring({
    from: { bottom: 25 },
    to: { bottom: DRAWER_GLOBAL_STYLES.doorClosedHeight },
    reverse: hideHangingFolders,
  })

  function onDrawerClicked(event) {
    beforeDrawerOpen && beforeDrawerOpen(event, drawer)
    onDrawerOpen && onDrawerOpen(event, drawer)
    afterDrawerOpen && afterDrawerOpen(event, drawer)
  }

  return (
    <Grid
      item
      style={{ top: containerYScroll }}
      classes={{
        root: classNames(classes.gridItem),
      }}
    >
      <div ref={doorRef} className={classes.root} onClick={onDrawerClicked}>
        <Box className={classes.drawerContent}>
          <animated.div style={animatedHangingFolderContainerWrapper} className={classes.hangingFolderContainerWrapper}>
            <CollapsedHangingFoldersContainer hangingFolders={hangingFolders} />
          </animated.div>
        </Box>
        <Paper elevation={hideHangingFolders ? 1 : 5} className={classes.door}>
          <Box className={classes.doorContentWrapper}>
            <Box className={classes.dropdownActionsWrapper}>
              {drawerConfig && drawerConfig.DropdownMenuComponent && (
                <DropdownMenu CustomComponent={drawerConfig.DropdownMenuComponent} />
              )}
            </Box>
            <Typography style={{ maxWidth: labelMaxWidth }} className={classes.drawerLabel}>
              {label}
            </Typography>
            <Box className={classes.handle} />
          </Box>
        </Paper>
      </div>
    </Grid>
  )
}

ClosedDrawer.defaultProps = {
  drawer: {
    label: 'drawer',
    color: colors.sugarYellow,
    hangingFolders: [],
  },
  hideHangingFolders: false,
  parentElementWidth: ARCHIVE_UI_GLOBAL_STYLES.width,
  parentElementHeight: ARCHIVE_UI_GLOBAL_STYLES.height,
}

export default ClosedDrawer
