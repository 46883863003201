import { makeStyles } from '@material-ui/core/styles'
import colors, { fade } from '../../constants/colors'
import GlobalStyles, {
  FILE_GLOBAL_STYLES,
  HANGING_FOLDER_GLOBAL_STYLES,
  NAVIGATION_WRAPPER_HEIGHT,
  TEXT_ELLIPSIS_STYLES,
} from '../GlobalStyles'

export const openedFileStyles = makeStyles((theme) => ({
  component: (props) => ({
    width: '100%',
    height: 'initial !important',
    position: 'absolute',
    top: 2,
    bottom: 0,
    right: 0,
    left: 0,
    marginTop: 0,
    marginBottom: 0,
    zIndex: props.index + FILE_GLOBAL_STYLES.zIndex,
  }),
  fileWrapper: (props) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    marginTop: 25,
    backgroundColor: props.file && props.file.color ? props.file.color : colors.file,
    borderRadius: `${GlobalStyles.defaultBorderRadius} ${GlobalStyles.defaultBorderRadius} 0 0`,
    borderTopLeftRadius: 0,
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 1px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
  }),
  labelIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: colors.sugarDarkGray,
  },
  label: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.sugarDarkGray,
    ...TEXT_ELLIPSIS_STYLES,
  },
  fileTag: (props) => ({
    backgroundColor: props.file && props.file.color ? props.file.color : colors.file,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: props.file && props.file.color ? props.file.color : colors.file,
    position: 'absolute',
    top: -25,
    left: 0,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    borderTopLeftRadius: GlobalStyles.defaultBorderRadius,
    borderTopRightRadius: GlobalStyles.defaultBorderRadius,
    fontSize: 20,
    fontWeight: 500,
    minWidth: FILE_GLOBAL_STYLES.width / 3,
  }),
  file: {
    position: 'absolute',
    top: 0,
    bottom: 14,
    left: 0,
    right: 0,
    borderRadius: GlobalStyles.defaultBorderRadius,
    backgroundColor: fade(colors.sugarGray, 0.9),
    padding: theme.spacing(2),
  },
  body: {
    height: '100%',
    position: 'relative',
  },
  documentsWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  documentsCountLabel: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 0.57,
  },
  dropdownActionsWrapper: {},
  documentsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: (props) => ({
    top: -(HANGING_FOLDER_GLOBAL_STYLES.headerHeight + 32) + 'px !important',
    zIndex: props.index + FILE_GLOBAL_STYLES.zIndex - 1 + ' !important',
  }),
  dropdownIconPrimaryColor: {
    color: colors.sugarDarkGray,
    '&:hover': {
      backgroundColor: fade(colors.sugarDarkGray, 0.9),
    },
  },
  navigation: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    zIndex: FILE_GLOBAL_STYLES.zIndex + 1,
  },
}))

export const closedFileStyles = makeStyles((theme) => ({
  component: {
    width: FILE_GLOBAL_STYLES.width,
    height: FILE_GLOBAL_STYLES.height,
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fileWrapper: (props) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    marginTop: 25,
    backgroundColor: props.file.color || colors.file,
    borderRadius: GlobalStyles.defaultBorderRadius,
    borderTopLeftRadius: 0,
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 1px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
  }),
  labelIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: 20,
    fontWeight: 500,
    ...TEXT_ELLIPSIS_STYLES,
  },
  fileTag: (props) => ({
    backgroundColor: props.file.color || colors.file,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: props.file.color || colors.file,
    position: 'absolute',
    top: -25,
    left: 0,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    borderTopLeftRadius: GlobalStyles.defaultBorderRadius,
    borderTopRightRadius: GlobalStyles.defaultBorderRadius,
    minWidth: FILE_GLOBAL_STYLES.width / 3,
  }),
  file: {
    position: 'absolute',
    top: FILE_GLOBAL_STYLES.height / 8,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: GlobalStyles.defaultBorderRadius,
    backgroundColor: fade(colors.sugarDarkGray, 0.9),
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  documentsCountLabel: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 0.57,
  },
  dropdownActionsWrapper: {},
  documentsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: (props) => ({
    top: -(HANGING_FOLDER_GLOBAL_STYLES.headerHeight + 32) + 'px !important',
    zIndex: props.index + FILE_GLOBAL_STYLES.zIndex - 1 + ' !important',
  }),
}))

export const fileContainerStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: NAVIGATION_WRAPPER_HEIGHT,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  filesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    overflowY: 'scroll',
    paddingLeft: 1,
    paddingRight: 1,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12),
  },
}))
