import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  typography: {
    fontFamily: '"PF Handbook Pro", "Roboto", "Helvetica", "Arial", sans-serif',
    htmlfontSize: 18,
    fontSize: 18,
    useNextVariants: true,
  },
  body1: {
    lineHeight: 1.4,
  },
})
