import React from 'react'
import { Button, Box } from '@material-ui/core'
import { navigationStyles } from '../GlobalStyles'
import Color from 'color'
import colors from '../../constants/colors'

const Navigation = (props) => {
  const { label, onClick, color } = props
  const classes = navigationStyles(props)
  const isColorDark = Color(color).isDark()

  function onButtonClicked(event) {
    onClick && onClick(event)
  }

  return (
    <Box className={classes.root}>
      <Button
        variant="contained"
        classes={{ root: classes.button, label: classes.buttonLabel }}
        style={{ color: isColorDark ? 'white' : 'inherit', borderColor: isColorDark ? 'white' : 'inherit' }}
        onClick={onButtonClicked}
      >
        {label}
      </Button>
    </Box>
  )
}

Navigation.defaultProps = {
  color: colors.sugarDarkGray,
  actions: null,
}

export default Navigation
