import Typography from '../assets/Typography'

export default {
  '@global html, body': {
    fontFamily: 'PF Handbook Pro',
  },
  '@font-face': [
    {
      fontFamily: 'PF Handbook Pro',
      fontStyle: 'normal',
      fontWeight: 400,
      src: `url(${Typography.PFHandbookProRegular}) format("woff")`,
    },
    {
      fontFamily: 'PF Handbook Pro',
      fontStyle: 'normal',
      fontWeight: 500,
      src: `url(${Typography.PFHandbookProMedium}) format("woff")`,
    },
    {
      fontFamily: 'PF Handbook Pro',
      fontStyle: 'italic',
      fontWeight: 400,
      src: `url(${Typography.PFHandbookProItalic}) format("woff")`,
    },
    {
      fontFamily: 'PF Handbook Pro',
      fontStyle: 'normal',
      fontWeight: 200,
      src: `url(${Typography.PFHandbookProExtraThin}) format("woff")`,
    },
    {
      fontFamily: 'PF Handbook Pro',
      fontStyle: 'normal',
      fontWeight: 300,
      src: `url(${Typography.PFHandbookProThin}) format("woff")`,
    },
    {
      fontFamily: 'PF Handbook Pro',
      fontStyle: 'normal',
      fontWeight: 600,
      src: `url(${Typography.PFHandbookProBold}) format("woff")`,
    },
    {
      fontFamily: 'PF Handbook Pro',
      fontStyle: 'normal',
      fontWeight: 700,
      src: `url(${Typography.PFHandbookProBlack}) format("woff")`,
    },
  ],
}
