import React from 'react'
import HangingFolder from '.'
import { expandedHangingFoldersContainerStyles } from './Styles'
import { Box } from '@material-ui/core'

const ExpandedHangingFoldersContainer = (props) => {
  const { hangingFolders, drawerLabel, ...otherProps } = props
  const classes = expandedHangingFoldersContainerStyles(props)

  return (
    <Box className={classes.root}>
      <Box className={classes.component}>
        <Box className={classes.hangingFoldersWrapper}>
          {hangingFolders &&
            hangingFolders.length > 0 &&
            hangingFolders.map((hangingFolder, hangingFolderIndex) => {
              return (
                <Box key={`hangingFolder-${hangingFolderIndex}`}>
                  <HangingFolder
                    label={hangingFolder.label}
                    files={hangingFolder.files}
                    index={hangingFolderIndex}
                    color={hangingFolder.color}
                    hangingFoldersCountFromParent={hangingFolders.length}
                    drawerLabel={drawerLabel && drawerLabel}
                    isLast={hangingFolders.length === hangingFolderIndex + 1}
                    {...otherProps}
                  />
                </Box>
              )
            })}
        </Box>
      </Box>
    </Box>
  )
}

ExpandedHangingFoldersContainer.defaultProps = {
  index: 0,
  isExpanded: true,
  hangingFolders: [],
}

export default ExpandedHangingFoldersContainer
