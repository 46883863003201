import { makeStyles } from '@material-ui/core/styles'
import colors, { fade } from '../../constants/colors'
import GlobalStyles, { HANGING_FOLDER_GLOBAL_STYLES, TEXT_ELLIPSIS_STYLES } from '../GlobalStyles'

export const hangingFolderStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    position: 'relative',
    zIndex: HANGING_FOLDER_GLOBAL_STYLES.zIndex + props.index,
    marginBottom: -20,
  }),
  openedRoot: (props) => ({
    height: 'initial !important',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    marginBottom: 0,
    zIndex: HANGING_FOLDER_GLOBAL_STYLES.zIndex + props.hangingFoldersCountFromParent,
  }),
  hangingFolder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    borderRadius: GlobalStyles.defaultBorderRadius,
  },
  header: {
    backgroundColor: fade(theme.palette.common.black, 0.75),
    borderRadius: GlobalStyles.defaultBorderRadius,
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: HANGING_FOLDER_GLOBAL_STYLES.headerHeight,
  },
  titleIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  headerWrapper: (props) => ({
    top: 3,
    position: 'relative',
    backgroundColor: props.color,
    borderRadius: GlobalStyles.defaultBorderRadius,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: fade(theme.palette.common.white, 0.5),
    boxShadow: 'rgba(256, 256, 256, 0.59) 0px -4px 2px -4px',
  }),
  body: (props) => ({
    backgroundColor: props.color,
    position: 'relative',
    top: 1,
    minHeight: HANGING_FOLDER_GLOBAL_STYLES.bodyHeight,
    marginLeft: 10,
    marginRight: 10,
  }),
  bodyLastElement: {
    minHeight: HANGING_FOLDER_GLOBAL_STYLES.bodyHeight + 80 + 'px !important',
  },
  name: {
    alignItems: 'center',
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    ...TEXT_ELLIPSIS_STYLES,
  },
  countWrapper: (props) => ({
    backgroundColor: props.color,
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: HANGING_FOLDER_GLOBAL_STYLES.zIndex + 5,
  }),
  count: {
    color: fade(theme.palette.common.white, 0.4),
    fontSize: theme.typography.body2.fontSize,
  },
  navigation: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    zIndex: HANGING_FOLDER_GLOBAL_STYLES.zIndex + 5,
  },
  emptyStateText: {
    color: theme.palette.common.white + ' !important',
  },
  closeButtonColorPrimary: {
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.9),
    },
  },
  closeButtonColorSecondary: {
    color: theme.palette.common.white + ' !important',
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.9) + ' !important',
    },
  },
}))

export const expandedHangingFoldersContainerStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  component: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    paddingLeft: 5,
    paddingRight: 5,
    boxSizing: 'border-box',
  },
  hangingFoldersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    pointerEvents: 'initial',
    paddingTop: theme.spacing(20),
    overflow: 'hidden',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  backdrop: {
    height: theme.spacing(20),
    backgroundColor: 'transparent',
  },
}))

export const collapsedHangingFolderStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    position: 'relative',
    zIndex: HANGING_FOLDER_GLOBAL_STYLES.zIndex + props.index,
    marginBottom: props.index + 2 - 20,
  }),
  component: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    paddingLeft: 5,
    paddingRight: 5,
    boxSizing: 'border-box',
  },
  hangingFolder: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: props.color || colors.sugarLightGray,
    borderRadius: GlobalStyles.defaultBorderRadius,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    height: 22,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: fade(theme.palette.common.white, 0.5),
  }),
}))

export const collapsedHangingFoldersContainerStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  component: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    paddingLeft: 5,
    paddingRight: 5,
    boxSizing: 'border-box',
  },
  hangingFoldersWrapper: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    pointerEvents: 'none',
    overflow: 'initial',
    overflowY: 'initial',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }),
}))
