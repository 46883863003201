import React from 'react'
import cabinetIconBlack from './svg/cabinet_black.svg'
import cabinetIconWhite from './svg/cabinet_white.svg'
import cabinetIconGray from './svg/cabinet_gray.svg'

const CabinetIcon = (props) => {
  const { width, height, color, ...otherProps } = props
  const iconSrc = getIconSrc()

  function getIconSrc() {
    switch (color) {
      case 'gray':
        return cabinetIconGray
      case 'white':
        return cabinetIconWhite
      default:
        return cabinetIconBlack
    }
  }

  return <img width={width} height={height} {...otherProps} src={iconSrc} alt={'Cabinet Icon'} />
}

CabinetIcon.defaultProps = {
  width: 25,
  height: 25,
  color: 'black',
}

export default CabinetIcon
