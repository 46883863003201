import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

const Icon = (props) => {
  const { children, ...otherProps } = props
  const classes = useStyles(props)
  return (
    <Box className={classes.root} {...otherProps}>
      {children}
    </Box>
  )
}

export default Icon
