import { makeStyles } from '@material-ui/core/styles'
import colors, { fade } from '../../constants/colors'
import { DOCUMENT_GLOBAL_STYLES } from '../GlobalStyles'

export const documentStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    maxWidth: DOCUMENT_GLOBAL_STYLES.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  buttonLabel: {
    flex: 1,
  },
  extendedPaper: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    width: '100% !important',
    height: '100% !important',
    zIndex: DOCUMENT_GLOBAL_STYLES.zIndex,
  },
  fileWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  documentViewer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  createdAt: {
    color: fade(theme.palette.common.black, 0.46),
    fontWeight: 500,
  },
  documentName: {
    color: fade(theme.palette.common.black, 0.46),
    fontWeight: 500,
    textTransform: 'initial',
  },
  documentIcon: {
    marginBottom: theme.spacing(1),
  },
  formatLabel: {
    color: colors.sugarDarkGray,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  placeholderButton: {
    backgroundColor: theme.palette.common.white,
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: colors.sugarDarkYellow,
    borderRadius: 4,
    padding: 14 + 'px !important',
    boxShadow: 'none !important',
  },
  placeholderContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  placeholderIconButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    pointerEvents: 'none',
    backgroundColor: fade(colors.sugarLightGray, 0.8),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}))

export const documentsContainerStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'stretch',
    padding: 5,
    overflow: 'hidden',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  documentWrapper: {
    marginRight: 10,
    marginLeft: 10,
  },
})
