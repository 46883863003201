import React, { useContext } from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { documentStyles } from './Styles'
import Icon from '../Other/Icon'
import FileIcon from '../../assets/icons/FileIcon'
import { NAVIGATION_WRAPPER_HEIGHT } from '../GlobalStyles'
import { ConfigContext } from '../Other/Context'
import ReactGA from 'react-ga'

const Document = (props) => {
  const { document, header, content, actions, parentElementWidth, parentElementHeight } = props
  const { label, createdAt, fileFormat } = document
  const classes = documentStyles(props)
  const config = useContext(ConfigContext)
  const documentConfig = config && config.document

  const width = parentElementWidth / 1.4
  const height = width + 200
  const maxHeight = parentElementHeight - NAVIGATION_WRAPPER_HEIGHT * 2

  function onClick(event) {
    const { onBeforeClick, onClick, onAfterClick } = documentConfig

    onBeforeClick && onBeforeClick(event, document)
    onClick && onClick(event, document)
    onAfterClick && onAfterClick(event, document)

    ReactGA.event({
      category: 'Document',
      action: 'Opened',
      label: document && document.label,
    })
  }

  function renderHeader() {
    if (header) {
      return header
    }

    return (
      <Typography className={classes.formatLabel} align={'right'} variant={'caption'}>
        {createdAt}
      </Typography>
    )
  }

  function renderContent() {
    if (content) {
      return content
    }

    return (
      <>
        <Icon className={classes.documentIcon}>
          <FileIcon width={35} height={35} color={'gray'} />
        </Icon>
        <Typography className={classes.documentName} variant={'h6'}>
          {label}.{fileFormat}
        </Typography>
      </>
    )
  }

  function renderActions() {
    if (actions) {
      return actions
    }

    return <Typography variant={'button'}>OPEN</Typography>
  }

  return (
    <Button
      elevation={1}
      style={{ width, height, maxHeight }}
      classes={{ root: classes.root, label: classes.buttonLabel }}
      onClick={onClick}
    >
      <Box className={classes.documentViewer}>
        <Box className={classes.header}>{renderHeader()}</Box>
        <Box className={classes.content}>{renderContent()}</Box>
        <Box className={classes.actions}>{renderActions()}</Box>
      </Box>
    </Button>
  )
}

Document.defaultProps = {
  parentElementWidth: 400,
  parentElementHeight: 520,
}

export default Document
