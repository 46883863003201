import { makeStyles } from '@material-ui/core/styles'
import { ARCHIVE_UI_GLOBAL_STYLES } from '../GlobalStyles'

export const archiveUIStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    boxSizing: 'border-box',
  },
}))

export const archiveUIContainerStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    minHeight: ARCHIVE_UI_GLOBAL_STYLES.height,
    boxSizing: 'border-box',
    overflow: 'hidden',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
})
