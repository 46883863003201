import React from 'react'
import folderIconBlack from './svg/folder_black.svg'
import folderIconGray from './svg/folder_gray.svg'
import folderIconWhite from './svg/folder_white.svg'

const FolderIcon = (props) => {
  const { width, height, color, ...otherProps } = props

  const iconSrc = getIconSrc()

  function getIconSrc() {
    switch (color) {
      case 'gray':
        return folderIconGray
      case 'white':
        return folderIconWhite
      default:
        return folderIconBlack
    }
  }

  return <img width={width} height={height} {...otherProps} src={iconSrc} alt={'Folder Icon'} />
}

FolderIcon.defaultProps = {
  width: 25,
  height: 25,
  color: 'black',
}

export default FolderIcon
