import React from 'react'
import fileIconBlack from './svg/file_black.svg'
import fileIconGray from './svg/file_gray.svg'
import fileIconWhite from './svg/file_white.svg'

const FileIcon = (props) => {
  const { width, height, color, ...otherProps } = props

  const iconSrc = getIconSrc()

  function getIconSrc() {
    switch (color) {
      case 'gray':
        return fileIconGray
      case 'white':
        return fileIconWhite
      default:
        return fileIconBlack
    }
  }

  return <img width={width} height={height} {...otherProps} src={iconSrc} alt={'File Icon'} />
}

FileIcon.defaultProps = {
  width: 25,
  height: 25,
  color: 'black',
}

export default FileIcon
