import { makeStyles } from '@material-ui/core/styles'
import colors, { gradient } from '../constants/colors'
export const DEFAULT_BORDER_RADIUS = 20
export const DEFAULT_VIEWPORT_HEIGHT = 500
export const DEFAULT_VIEWPORT_WIDTH = 320
export const NAVIGATION_WRAPPER_HEIGHT = 55
export const SUGAR_YELLOW_GRADIENT = gradient(colors.sugarDarkYellow, colors.sugarYellow)

export const CABINET_GLOBAL_STYLES = {
  zIndex: 600,
  borderRadius: DEFAULT_BORDER_RADIUS / 2,
}

export const DRAWER_GLOBAL_STYLES = {
  doorOpenHeight: 45,
  doorClosedHeight: 175,
  minLabelWidth: 150,
  zIndex: 700,
}

export const HANGING_FOLDER_GLOBAL_STYLES = {
  sideEdgeWidth: 10,
  containerHeight: 500,
  headerHeight: 28,
  bodyHeight: 50,
  height: DRAWER_GLOBAL_STYLES.doorClosedHeight - (50 + 28 / 2),
  zIndex: 810,
}

export const FILE_GLOBAL_STYLES = {
  height: 220,
  width: 350,
  zIndex: 920,
}

export const DOCUMENT_GLOBAL_STYLES = {
  maxWidth: 500,
  width: 300,
  height: 480,
  zIndex: 930,
}

export const ARCHIVE_UI_GLOBAL_STYLES = {
  width: DEFAULT_VIEWPORT_WIDTH,
  height: DEFAULT_VIEWPORT_HEIGHT,
}

export default {
  defaultBorderRadius: DEFAULT_BORDER_RADIUS,
}

export const TEXT_ELLIPSIS_STYLES = {
  whiteSpace: 'pre',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}

export const navigationStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    position: 'absolute',
    bottom: 15,
    left: 15,
    right: 15,
    minWidth: 100,
    maxWidth: '85%',
  },
  button: (props) => ({
    width: '100%',
    minHeight: NAVIGATION_WRAPPER_HEIGHT,
    borderRadius: `${DEFAULT_BORDER_RADIUS}px !important`,
    border: '1px solid white',
    backgroundColor: props.color + ' !important',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: props.color + ' !important',
    },
  }),
  buttonLabel: {
    display: 'initial !important',
    ...TEXT_ELLIPSIS_STYLES,
  },
  dropdownActionsWrapper: {
    position: 'absolute',
    right: 10,
    bottom: 12,
    top: 12,
  },
}))
