import { useState, useEffect } from 'react'

export const useDialogState = (initialState) => {
  const [open, setOpen] = useState(initialState)

  function openDialog() {
    setOpen(true)
  }

  function closeDialog() {
    setOpen(false)
  }

  return [open, openDialog, closeDialog]
}

function getWindowDimensions() {
  const { innerWidth: viewportWidth, innerHeight: viewportHeight } = window
  return {
    viewportWidth,
    viewportHeight,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  function handleResize() {
    setWindowDimensions(getWindowDimensions())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export const useElementDimensions = (ref, dependencies) => {
  const [height, setHeight] = useState(null)
  const [width, setWidth] = useState(null)

  useEffect(() => {
    if (ref && ref.current && ref.current.getBoundingClientRect) {
      const { height, width } = ref.current.getBoundingClientRect()
      setWidth(width)
      setHeight(height)
    }
  }, [ref, dependencies])

  return { elementHeight: height, elementWidth: width }
}
