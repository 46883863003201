import { makeStyles } from '@material-ui/core/styles'
import colors, { lighten } from '../../constants/colors'
import GlobalStyles, {
  DRAWER_GLOBAL_STYLES,
  SUGAR_YELLOW_GRADIENT,
  DEFAULT_BORDER_RADIUS,
  TEXT_ELLIPSIS_STYLES,
} from '../GlobalStyles'

export const openedDrawerStyles = makeStyles((theme) => ({
  gridItem: (props) => ({
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: props.parentElementHeight,
    marginBottom: props.isLast && 0,
    zIndex: DRAWER_GLOBAL_STYLES.zIndex + 2,
  }),
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
  },
  drawerContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: DRAWER_GLOBAL_STYLES.zIndex + 1,
    overflow: 'hidden',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  hangingFolderContainerWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
  door: (props) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: lighten(props.drawer && props.drawer.color ? props.drawer.color : colors.sugarYellow, 0.4),
    width: '100%',
    zIndex: DRAWER_GLOBAL_STYLES.zIndex + props.index,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: DRAWER_GLOBAL_STYLES.doorOpenHeight,
    borderRadius: GlobalStyles.defaultBorderRadius + 'px !important',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }),
  drawerLabel: {
    borderRadius: GlobalStyles.defaultBorderRadius,
    padding: 10,
    minWidth: '30vw',
    backgroundColor: theme.palette.common.white,
    borderColor: colors.sugarDarkYellow,
    textAlign: 'center',
    color: colors.sugarLightGray,
    fontWeight: 600,
    textTransform: 'uppercase',
    ...TEXT_ELLIPSIS_STYLES,
  },
  navigation: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: DRAWER_GLOBAL_STYLES.zIndex + 2,
  },
  navigationRoot: {
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    maxWidth: 'initial',
  },
  navigationButton: {
    borderBottomLeftRadius: 0 + ' !important',
    borderBottomRightRadius: 0 + ' !important',
    background: SUGAR_YELLOW_GRADIENT,
    minHeight: theme.spacing(9),
    borderStyle: 'none !important',
    borderColor: 'transparent !important',
  },
  backdrop: {
    zIndex: DRAWER_GLOBAL_STYLES.zIndex,
  },
  emptyStateWrapper: {
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: '20%',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    borderTopLeftRadius: DEFAULT_BORDER_RADIUS,
    borderTopRightRadius: DEFAULT_BORDER_RADIUS,
  },
}))

export const closedDrawerStyles = makeStyles((theme) => ({
  gridItem: {
    position: 'relative',
    height: DRAWER_GLOBAL_STYLES.doorClosedHeight + 30,
    marginBottom: theme.spacing(4),
  },
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
  },
  drawerContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: DRAWER_GLOBAL_STYLES.zIndex - 1,
  },
  hangingFolderContainerWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 20,
    left: 0,
    right: 0,
    width: '100%',
  },
  door: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: DRAWER_GLOBAL_STYLES.doorClosedHeight,
    borderRadius: GlobalStyles.defaultBorderRadius + 'px !important',
    background: SUGAR_YELLOW_GRADIENT,
    width: '100%',
    zIndex: DRAWER_GLOBAL_STYLES.zIndex,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerLabel: {
    borderRadius: GlobalStyles.defaultBorderRadius,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minWidth: DRAWER_GLOBAL_STYLES.minLabelWidth,
    backgroundColor: theme.palette.common.white,
    borderColor: colors.sugarDarkYellow,
    fontWeight: 600,
    textAlign: 'center',
    color: colors.sugarLightGray,
    textTransform: 'capitalize',
    ...TEXT_ELLIPSIS_STYLES,
  },
  doorContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
  },
  handle: {
    width: theme.spacing(5),
    height: theme.spacing(1),
    backgroundColor: colors.sugarDarkYellow,
    borderRadius: GlobalStyles.defaultBorderRadius,
    marginTop: theme.spacing(3),
  },
  dropdownActionsWrapper: {
    position: 'absolute',
    right: 10,
    bottom: 12,
    top: 12,
  },
}))

export const drawersContainerStyles = makeStyles((theme) => ({
  gridContainer: {
    position: 'relative',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(18),
  },
}))
