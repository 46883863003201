import React, { useState, useRef } from 'react'
import { Box, Button, makeStyles } from '@material-ui/core'
import Slider from 'react-slick'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import MagicSliderDots from 'react-magic-slider-dots'
import './Carousel.css'

const carouselStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `${theme.spacing(1)}px 0`,
  },
  magicSliderDotsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: `${theme.spacing(1)}px 0`,
  },
  arrowButton: {
    minWidth: 100,
  },
}))

const Carousel = (props) => {
  const { children, showArrows, showDots, showNavigation, disable, SliderProps, afterSlideChanged } = props
  const classes = carouselStyles(props)
  const [currentSlide, setCurrentSlide] = useState(0)
  const SliderRef = useRef()

  function afterChange(index) {
    if (currentSlide !== index) {
      setCurrentSlide(index)
      afterSlideChanged(index)
    }
  }

  function nextSlide() {
    if (!disable) {
      SliderRef.current.slickNext()
    }
  }

  function previousSlide() {
    if (!disable) {
      SliderRef.current.slickPrev()
    }
  }

  function appendDots(dots) {
    if (!showNavigation && children && children.length > 0) {
      return <></>
    }

    return (
      <Box>
        <Box className={classes.magicSliderDotsWrapper}>
          <Box className={classes.navigation} style={{ width: '100%' }}>
            {showArrows && (
              <Button size={'large'} className={classes.arrowButton} onClick={previousSlide}>
                <NavigateBeforeIcon />
              </Button>
            )}
            <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={20} />
            {showArrows && (
              <Button size={'large'} className={classes.arrowButton} onClick={nextSlide}>
                <NavigateNextIcon />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Slider
        ref={SliderRef}
        dots={showDots}
        infinite={false}
        speed={500}
        appendDots={appendDots}
        draggable={!disable}
        touchMove={!disable}
        arrows={false}
        afterChange={afterChange}
        className={disable && 'disabled'}
        {...SliderProps}
      >
        {children}
      </Slider>
    </Box>
  )
}

Carousel.defaultProps = {
  showArrows: true,
  showDots: true,
  showNavigation: true,
  disable: false,
}

export default Carousel
