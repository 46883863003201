import { makeStyles } from '@material-ui/core/styles'
import { CABINET_GLOBAL_STYLES, DRAWER_GLOBAL_STYLES, TEXT_ELLIPSIS_STYLES } from '../GlobalStyles'
import colors from '../../constants/colors'

export const cabinetStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundColor: props.color,
    borderRadius: CABINET_GLOBAL_STYLES.borderRadius,
    zIndex: CABINET_GLOBAL_STYLES.zIndex,
    padding: theme.spacing(3),
    boxSizing: 'border-box',
    transition: 'background-color ease-in 0.3',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
  }),
  openRoot: {
    borderBottomLeftRadius: 0 + ' !important',
    borderBottomRightRadius: 0 + ' !important',
    margin: 0 + 'px !important',
  },
  cabinet: {
    height: '100%',
  },
  contentWrapper: {
    position: 'relative',
    height: '100%',
  },
  contentWrapperDisabled: {
    position: 'initial',
  },
  header: {
    padding: theme.spacing(1),
    borderRadius: CABINET_GLOBAL_STYLES.borderRadius,
    marginBottom: theme.spacing(1),
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: DRAWER_GLOBAL_STYLES.zIndex + 1,
      boxShadow: `0 0 5px 10px ${colors.sugarGray}`,
    },
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: 26,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: colors.sugarDarkGray,
    ...TEXT_ELLIPSIS_STYLES,
  },
  content: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    overflowY: 'scroll',
    transition: 'opacity ease-in 0.3',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  contentOpen: {
    opacity: 1,
  },
  contentDisabled: {
    position: 'relative',
    overflowY: 'hidden',
  },
  navigation: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    zIndex: DRAWER_GLOBAL_STYLES.zIndex + 1,
  },
  drawerWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  drawer: {
    height: '100% !important',
  },
}))

export const cabinetsContainerStyles = makeStyles((theme) => ({
  root: (props) => ({
    height: props.parentElementHeight,
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box',
    overflow: 'auto',
  }),
  cabinetsContainer: {
    position: 'relative',
    height: '100%',
    outline: 'none',
    boxSizing: 'border-box',
    overflow: 'auto',
  },
  cabinet: {
    margin: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(9)}px`,
    transition: 'margin 0.3s ease-out 0.3s',
  },
}))
