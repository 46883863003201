import React, { useContext } from 'react'
import Document from '.'
import { documentsContainerStyles } from './Styles'
import { Box } from '@material-ui/core'
import { ConfigContext } from '../Other/Context'
import { animated, useSpring } from 'react-spring'

const DocumentsContainer = (props) => {
  const { documents, ...otherProp } = props
  const classes = documentsContainerStyles(props)
  const config = useContext(ConfigContext)
  const documentConfig = config && config.document

  const animatedDocumentWrapper = useSpring({
    from: { opacity: 0, transform: 'translate3d(30px,0,0)' },
    to: { opacity: 1, transform: 'translate3d(0px, 0, 0)' },
    config: {
      tension: 450,
    },
  })

  return (
    <Box className={classes.root}>
      {documents &&
        documents.length > 0 &&
        documents.map((document, documentIndex) => {
          return (
            <animated.div
              style={animatedDocumentWrapper}
              key={`document-${documentIndex}`}
              className={classes.documentWrapper}
            >
              <Document document={document} index={documentIndex} {...otherProp} />
            </animated.div>
          )
        })}
    </Box>
  )
}

export default DocumentsContainer
