import React, { useState, useEffect, useRef, useContext } from 'react'
import { Box, Typography } from '@material-ui/core'
import OpenedDrawer from '../../components/Drawer/OpenedDrawer'
import DrawersContainer from '../../components/Drawer/DrawersContainer'
import { cabinetStyles } from './Styles'
import { useDialogState } from '../../hooks'
import colors from '../../constants/colors'
import classNames from 'classnames'
import { DEFAULT_VIEWPORT_HEIGHT } from '../GlobalStyles'
import { useElementDimensions } from '../../hooks'
import Icon from '../Other/Icon'
import CabinetIcon from '../../assets/icons/CabinetIcon'
import DropdownMenu from '../Other/DropdownMenu'
import EmptyState from '../Other/EmptyState'
import { ConfigContext } from '../Other/Context'
import SpinLoader from '../Other/SpinLoader'
import ReactGA from 'react-ga'

const Cabinet = (props) => {
  const {
    label,
    drawers,
    header,
    content,
    afterCabinetOpened,
    afterCabinetClosed,
    afterDrawerOpened,
    afterDrawerClosed,
    isOpen,
    parentElementWidth,
    parentElementHeight,
  } = props
  const classes = cabinetStyles(props)
  const [isCabinetOpen, openCabinet, closeCabinet] = useDialogState(isOpen)
  const [selectedDrawer, setSelectedDrawer] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const ref = useRef()
  const headerRef = useRef()
  const { elementHeight } = useElementDimensions(ref, [parentElementHeight])
  const { elementWidth } = useElementDimensions(headerRef, [parentElementWidth])
  const config = useContext(ConfigContext)
  const cabinetConfig = config && config.cabinet
  const headerElementWidth = getHeaderElementWidth()

  useEffect(() => {
    if (isOpen) {
      openCabinet()
      afterCabinetOpened && afterCabinetOpened()
    } else {
      closeCabinet()
      afterCabinetClosed && afterCabinetClosed()
    }
  }, [isOpen])

  useEffect(() => {
    if (drawers) {
      setLoaded(true)
    }
  }, [drawers])

  function getHeaderElementWidth() {
    let headerWidth = elementWidth - 50
    if (cabinetConfig && cabinetConfig.DropdownMenuComponent) {
      return headerWidth - 55
    }

    return headerWidth
  }

  function onDrawerOpen(event, drawer) {
    setSelectedDrawer(drawer)
    afterDrawerOpened && afterDrawerOpened(event)

    ReactGA.event({
      category: 'Drawer',
      action: 'Opened',
      label: drawer && drawer.label,
    })
  }

  function onDrawerClose(event, drawer) {
    setSelectedDrawer(null)
    afterDrawerClosed && afterDrawerClosed(event)

    ReactGA.event({
      category: 'Drawer',
      action: 'Closed',
      label: drawer && drawer.label,
    })
  }

  function renderHeader() {
    if (header) {
      return header
    }

    return (
      <div ref={headerRef} className={classes.headerWrapper}>
        <Box className={classes.titleIconWrapper}>
          <Icon style={{ marginRight: 10 }}>
            <CabinetIcon color={'gray'} />
          </Icon>
          <Typography style={{ maxWidth: headerElementWidth }} classes={{ root: classes.label }}>
            {label}
          </Typography>
        </Box>
        {cabinetConfig && cabinetConfig.DropdownMenuComponent && (
          <DropdownMenu CustomComponent={cabinetConfig.DropdownMenuComponent} />
        )}
      </div>
    )
  }

  function renderContent() {
    if (content) {
      return content
    }

    if (!loaded) {
      return <SpinLoader color={colors.sugarDarkGray} />
    }

    if (loaded && drawers) {
      if (drawers.length === 0) {
        return (
          <EmptyState
            title={'There are no drawers in this cabinet'}
            description={'You need to create a new drawer first'}
            icon={<CabinetIcon width={50} height={50} color={'gray'} />}
            actionButtonLabel={'New drawer'}
            actionButtonOnClick={cabinetConfig && cabinetConfig.onCreate}
          />
        )
      }

      return (
        <DrawersContainer
          hideHangingFolders={!isCabinetOpen}
          onDrawerOpen={onDrawerOpen}
          drawers={drawers}
          parentElementWidth={elementWidth || parentElementWidth}
          parentElementHeight={elementHeight || parentElementHeight}
        />
      )
    }
  }

  return (
    <div ref={ref} className={classNames(classes.root, Boolean(selectedDrawer) && classes.openRoot)}>
      <Box className={classes.cabinet}>
        <Box className={classes.header}>{renderHeader()}</Box>
        <Box className={classNames(classes.contentWrapper, selectedDrawer && classes.contentWrapperDisabled)}>
          <Box
            className={classNames(
              classes.content,
              isCabinetOpen && classes.contentOpen,
              selectedDrawer && classes.contentDisabled,
            )}
          >
            {renderContent()}
          </Box>
          {Boolean(selectedDrawer) && (
            <Box className={classes.drawerWrapper}>
              <OpenedDrawer
                drawer={selectedDrawer}
                onDrawerClose={onDrawerClose}
                classes={{ gridItem: classes.drawer }}
                parentElementWidth={elementWidth || parentElementWidth}
                parentElementHeight={elementHeight || parentElementHeight}
                animate
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  )
}

Cabinet.defaultProps = {
  label: 'cabinet',
  isOpen: false,
  color: colors.sugarGray,
  parentElementHeight: DEFAULT_VIEWPORT_HEIGHT,
}

export default Cabinet
