import React from 'react'
import { Box, makeStyles, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circularProgressRoot: (props) => ({
    color: (props.color && props.color) || theme.palette.common.white,
  }),
}))

const SpinLoader = (props) => {
  const { color } = props
  const classes = useStyles(props)

  return (
    <Box className={classes.root}>
      <CircularProgress classes={{ root: classes.circularProgressRoot }} />
    </Box>
  )
}

export default SpinLoader
