import Color from 'color'

export const fade = (color, ratio) => {
  return Color(color).fade(ratio).toString()
}

export const darken = (color, ratio) => {
  return Color(color).darken(ratio).toString()
}

export const lighten = (color, ratio) => {
  return Color(color).lighten(ratio).toString()
}

export const whiten = (color, ratio) => {
  return Color(color).whiten(ratio).toString()
}

export const blacken = (color, ratio) => {
  return Color(color).blacken(ratio).toString()
}

export const saturate = (color, ratio) => {
  return Color(color).saturate(ratio).toString()
}

export const desaturate = (color, ratio) => {
  return Color(color).desaturate(ratio).toString()
}

export const gradient = (color1, color2, deg = 180) => {
  return `linear-gradient(${deg}deg, ${Color(color1).toString()} 0%, ${Color(color2).toString()} 100%)`
}

export default {
  sugarLightGray: '#7F7F7F',
  sugarDarkGray: '#5C5B5B',
  sugarGray: '#EBECEC',
  sugarYellow: '#FCCA25',
  sugarDarkYellow: '#DA9E28',

  drawerDoor: '#7F7F7F',
  hangingFolder: '#fac724',
  file: '#FFFFFF',

  white: '#FFFFFF',
}
