import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import colors from '../../constants/colors'

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: colors.sugarYellow,
  },
}))

const ActionButton = (props) => {
  const { children, ...otherProps } = props
  const classes = useStyles(props)
  return (
    <Button className={classes.button} {...otherProps}>
      {children}
    </Button>
  )
}

export default ActionButton
