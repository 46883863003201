import React, { useRef, useState, useEffect, useContext } from 'react'
import DocumentsContainer from '../Document/DocumentsContainer'
import { openedFileStyles } from './Styles'
import colors from '../../constants/colors'
import { Box, Typography } from '@material-ui/core'
import Backdrop from '../Other/Backdrop'
import Navigation from '../Other/Navigation'
import { useWindowDimensions, useElementDimensions } from '../../hooks'
import Icon from '../Other/Icon'
import FolderIcon from '../../assets/icons/FolderIcon'
import FileIcon from '../../assets/icons/FileIcon'
import DropdownMenu from '../Other/DropdownMenu'
import EmptyState from '../Other/EmptyState'
import { ConfigContext } from '../Other/Context'
import SpinLoader from '../Other/SpinLoader'

const OpenedFile = (props) => {
  const { file, onBeforeFileClosed, onFileClosed, onAfterFileClosed } = props
  const classes = openedFileStyles(props)
  const documentWrapperRef = useRef()
  const { viewportHeight } = useWindowDimensions()
  const { elementWidth, elementHeight } = useElementDimensions(documentWrapperRef, [viewportHeight])
  const [loaded, setLoaded] = useState(false)
  const config = useContext(ConfigContext)
  const fileConfig = config && config.file
  const documentConfig = config && config.document

  useEffect(() => {
    if (file) {
      setLoaded(true)
    }
  }, [file])

  function onClose(event) {
    event.stopPropagation()
    onBeforeFileClosed && onBeforeFileClosed(event, file)
    onFileClosed && onFileClosed(event, file)
    onAfterFileClosed && onAfterFileClosed(event, file)
  }

  function getCountLabel() {
    if (file && file.documents && file.documents.length) {
      if (file.documents.length === 1) {
        return file.documents.length + ' document'
      } else {
        return file.documents.length + ' documents'
      }
    } else {
      return '0 documents'
    }
  }

  function renderBody() {
    if (file) {
      if (file.documents && file.documents.length === 0) {
        return (
          <EmptyState
            title={'There are no documents in this file'}
            description={'You need to upload a document first'}
            icon={<FileIcon width={50} height={50} color={'gray'} />}
            actionButtonLabel={'New document'}
            actionButtonOnClick={documentConfig && documentConfig.onCreate}
          />
        )
      }

      return (
        <Box className={classes.documentsWrapper} ref={documentWrapperRef}>
          <DocumentsContainer
            documents={file && file.documents}
            classes={{ root: classes.documentsContainer }}
            parentElementWidth={elementWidth}
            parentElementHeight={elementHeight}
          />
        </Box>
      )
    }
  }

  return (
    <Box>
      <Box className={classes.component}>
        <Box className={classes.fileWrapper}>
          <Box className={classes.fileTag}>
            <Box className={classes.labelIconWrapper}>
              <Icon style={{ marginRight: 8 }}>
                <FolderIcon width={20} height={20} color={'gray'} />
              </Icon>
              <Typography style={{ maxWidth: elementWidth - 80 }} className={classes.label}>
                {file && file.label ? file.label : 'File'}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.file}>
            <Box className={classes.header}>
              <Typography className={classes.documentsCountLabel}>{getCountLabel()}</Typography>
              <Box className={classes.dropdownActionsWrapper}>
                {fileConfig && fileConfig.DropdownMenuComponent && (
                  <DropdownMenu CustomComponent={fileConfig.DropdownMenuComponent} />
                )}
              </Box>
            </Box>
            <Box className={classes.body}>
              {!loaded && <SpinLoader color={colors.sugarDarkGray} />}
              {loaded && renderBody()}
            </Box>
          </Box>
          <Box className={classes.navigation}>
            <Navigation color="#ffff" label={`close ${file && file.label ? file.label : 'file'}`} onClick={onClose} />
          </Box>
        </Box>
      </Box>
      <Backdrop onClick={onClose} classes={{ root: classes.backdrop }} />
    </Box>
  )
}

OpenedFile.defaultProps = {
  index: 0,
}

export default OpenedFile
