import React, { Fragment } from 'react'
import { AppBar, Toolbar, Typography, IconButton, Box, makeStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { DEFAULT_BORDER_RADIUS, NAVIGATION_WRAPPER_HEIGHT } from '../GlobalStyles'
import Navigation from '../Other/Navigation'

const useStyles = makeStyles((theme) => ({
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3),
    border: '1px solid gray',
    borderRadius: DEFAULT_BORDER_RADIUS,
    height: '100%',
    marginBottom: NAVIGATION_WRAPPER_HEIGHT + 50,
  },
  headerWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

const DefaultDocumentViewer = (props) => {
  const { document, onClose } = props
  const classes = useStyles(props)

  function onDocumentClosed(event) {
    onClose && onClose(event, document)
  }

  return (
    <Fragment>
      <AppBar color="default" position="relative">
        <Toolbar>
          <IconButton edge="start" size="small" color="inherit" onClick={onClose} aria-label="close">
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          {document && (
            <Box className={classes.headerWrapper}>
              <Typography variant="subtitle1">{document.label}</Typography>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box className={classes.body}>
        <Typography variant="h6">Document preview</Typography>
      </Box>
      <Box className={classes.navigation}>
        <Navigation color="#ffff" label={`Close Document`} onClick={onDocumentClosed} />
      </Box>
    </Fragment>
  )
}

export default DefaultDocumentViewer
