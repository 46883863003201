import React, { useState } from 'react'
import { Grid, Dialog, Slide, makeStyles } from '@material-ui/core'
import ArchiveUI from './components/ArchiveUI/index'
import { mockedCabinets } from './data/mockedData'
import DefaultDocumentViewer from './components/Document/DefaultDocumentViewer'
import ReactGA from 'react-ga'

const useStyles = makeStyles({
  gridContainer: {
    height: '100%',
  },
  gridItem: {
    height: '100%',
    maxWidth: 550,
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ArchiveScene = (props) => {
  const classes = useStyles(props)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentDocument, setCurrentDocument] = useState(null)

  const archiveUiConfig = {
    cabinet: {
      dropdownActions: [
        // {
        //   label: 'Rename',
        //   icon: <PencilIcon />,
        //   action: handleRenameButton,
        //   condition: true,
        // },
      ],
      onCreate: onCabinetCreate,
    },
    drawer: {
      dropdownActions: [],
      onCreate: onDrawerCreate,
    },
    hangingFolder: {
      dropdownActions: [],
      onCreate: onHangingFolderCreate,
    },
    file: {
      dropdownActions: [],
      onCreate: onFileCreate,
    },
    document: {
      onClick: onDocumentClicked,
      onCreate: onDocumentCreate,
    },
  }

  function onDocumentClicked(event, document) {
    setCurrentDocument(document)
    setDialogOpen(true)
  }

  function closeDialog(event, document) {
    setCurrentDocument(null)
    setDialogOpen(false)

    ReactGA.event({
      category: 'Document',
      action: 'Closed',
      label: document && document.label,
    })
  }

  function onDocumentCreate(event, file) {
    console.log('create new document callback', file)
  }

  function onFileCreate(event, hangingFolder) {
    console.log('create new file callback', hangingFolder)
  }

  function onHangingFolderCreate(event, drawer) {
    console.log('create new hangingFolder callback', drawer)
  }

  function onDrawerCreate(event, cabinet) {
    console.log('create new drawer callback', cabinet)
  }

  function onCabinetCreate(event) {
    console.log('create new cabinet callback')
  }

  return (
    <Grid container alignItems={'center'} justify={'center'} classes={{ root: classes.gridContainer }}>
      <Grid item xs={12} md={6} classes={{ root: classes.gridItem }}>
        <ArchiveUI config={archiveUiConfig} data={mockedCabinets} />
        <Dialog fullScreen open={Boolean(dialogOpen)} onClose={closeDialog} TransitionComponent={Transition}>
          <DefaultDocumentViewer document={currentDocument} onClose={closeDialog} />
        </Dialog>
      </Grid>
    </Grid>
  )
}

export default ArchiveScene
