import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import colors from '../../constants/colors'
import classNames from 'classnames'
import ActionButton from '../Other/ActionButton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  small: {
    '& $icon': {
      fontSize: 18,
      marginBottom: 14,
    },
    '& $title': {
      fontSize: 14,
    },
    '& $description': {
      marginTop: 5,
      fontSize: 12,
    },
  },
  large: {
    '& $icon': {
      fontSize: 56,
      marginBottom: 28,
    },
    '& $title': {
      fontSize: 20,
    },
    '& $description': {
      marginTop: 16,
    },
  },
  icon: {
    fontSize: 42,
    marginBottom: 22,
  },
  title: {
    color: theme.palette.common.black,
    marginTop: theme.spacing(1),
    textAlign: 'center',
    lineHeight: 1.4,
  },
  description: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.common.black,
  },
  actionButtonWrapper: {
    marginTop: theme.spacing(3),
  },
}))

const EmptyState = (props) => {
  const { size, title, description, icon, children, actionButtonLabel, actionButtonOnClick } = props
  const classes = useStyles(props)

  return (
    <Box className={classNames(classes.root, { [classes[size]]: size !== 'medium' })}>
      <Box className={classes.wrapper}>
        {icon && icon}
        {title && (
          <Typography variant={'h6'} className={classes.title}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant={'body2'} className={classes.description}>
            {description}
          </Typography>
        )}
        {/* {actionButtonOnClick && (
          <Box className={classes.actionButtonWrapper}>
            <ActionButton onClick={actionButtonOnClick} variant="contained">
              {actionButtonLabel}
            </ActionButton>
          </Box>
        )} */}
        {children && children}
      </Box>
    </Box>
  )
}

EmptyState.defaultProps = {
  size: 'medium',
  actionButtonLabel: 'NEW',
}

export default EmptyState
