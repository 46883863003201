import React from 'react'
import { Box } from '@material-ui/core'
import { archiveUIContainerStyles } from './Styles'
import CabinetsContainer from '../Cabinet/CabinetsContainer'

const ArchiveUIContainer = (props) => {
  const { data, parentElementHeight } = props
  const classes = archiveUIContainerStyles(props)

  return (
    <Box style={{ height: parentElementHeight }} className={classes.root}>
      <CabinetsContainer cabinets={data} parentElementHeight={parentElementHeight} />
    </Box>
  )
}

export default ArchiveUIContainer
