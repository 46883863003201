import React from 'react'

export const ConfigContext = React.createContext({
  config: {
    cabinet: {
      dropdownActions: [],
      onCreate: () => {},
    },
    drawer: {
      dropdownActions: [],
      onCreate: () => {},
    },
    hangingFolder: {
      dropdownActions: [],
      onCreate: () => {},
    },
    file: {
      dropdownActions: [],
      onCreate: () => {},
    },
    document: {
      onClick: () => {},
      onCreate: () => {},
    },
  },
})
