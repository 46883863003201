import React, { useState, useRef, useContext, useEffect } from 'react'
import FilesContainer from '../File/FilesContainer'
import OpenedFile from '../File/OpenedFile'
import { hangingFolderStyles } from './Styles'
import { Box, Typography, IconButton } from '@material-ui/core'
import colors from '../../constants/colors'
import classNames from 'classnames'
import Navigation from '../Other/Navigation'
import { useSpring, animated } from 'react-spring'
import { DEFAULT_VIEWPORT_HEIGHT, HANGING_FOLDER_GLOBAL_STYLES, DEFAULT_VIEWPORT_WIDTH } from '../GlobalStyles'
import Icon from '../Other/Icon'
import HangingFolderIcon from '../../assets/icons/HangingFolderIcon'
import FileIcon from '../../assets/icons/FileIcon'
import EmptyState from '../Other/EmptyState'
import { ConfigContext } from '../Other/Context'
import SpinLoader from '../Other/SpinLoader'
import { useElementDimensions } from '../../hooks'
import CloseIcon from '@material-ui/icons/Close'
import ReactGA from 'react-ga'

const HangingFolder = (props) => {
  const {
    label,
    files,
    isLast,
    color,
    parentElementWidth,
    parentElementHeight,
    afterHangingFolderOpen,
    afterHangingFolderClose,
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [isHangingFolderOpen, setIsHangingFolderOpen] = useState(false)
  const [openedFile, setOpenedFile] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const classes = hangingFolderStyles(props)
  const config = useContext(ConfigContext)
  const hangingFolderConfig = config && config.hangingFolder
  const fileConfig = config && config.file
  const headerRef = useRef()
  const { elementWidth } = useElementDimensions(headerRef, [parentElementWidth])
  const labelMaxWidth = getHeaderElementWidth()

  useEffect(() => {
    if (files) {
      setLoaded(true)
    }
  }, [files])

  const animatedBody = useSpring({
    from: {
      height: HANGING_FOLDER_GLOBAL_STYLES.bodyHeight,
    },
    to: {
      height: parentElementHeight + 25, //(- 63)
    },
    config: {
      tension: 150,
    },
    reverse: !isOpen,
  })

  const animatedNavigation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      tension: 150,
    },
    delay: 300,
    reverse: !isHangingFolderOpen,
  })

  function getHeaderElementWidth() {
    let headerWidth = elementWidth - 60
    if (fileConfig && fileConfig.DropdownMenuComponent) {
      return headerWidth - 55
    }

    return headerWidth
  }

  function openHangingFolder(event) {
    setIsOpen(true)
    setIsHangingFolderOpen(true)
    afterHangingFolderOpen && afterHangingFolderOpen(event, { label })
  }

  function closeHangingFolder(event) {
    setIsHangingFolderOpen(false)
    setTimeout(() => {
      setIsOpen(false)
      afterHangingFolderClose && afterHangingFolderClose(event, { label })
    }, 300)
  }

  function onFileClicked(event, file) {
    setOpenedFile(file)

    ReactGA.event({
      category: 'File',
      action: 'Opened',
      label: file && file.label,
    })
  }

  function onFileClosed(event, file) {
    setOpenedFile(null)

    ReactGA.event({
      category: 'File',
      action: 'Closed',
      label: file && file.label,
    })
  }

  function getCountLabel() {
    if (files && files.length === 1) {
      return files.length + ' file'
    } else if (files && files.length > 1) {
      return files.length + ' files'
    } else {
      return '0 files'
    }
  }

  function renderBody() {
    if (isOpen) {
      if (!loaded) {
        return <SpinLoader />
      }
      if (files) {
        if (files.length === 0) {
          return (
            <EmptyState
              classes={{ title: classes.emptyStateText, description: classes.emptyStateText }}
              title={'There are no files in this hanging folder'}
              description={'You need to create a file first'}
              icon={<FileIcon width={50} height={50} color={'white'} />}
              actionButtonLabel={'New file'}
              actionButtonOnClick={fileConfig && fileConfig.onCreate}
            />
          )
        }

        return (
          <FilesContainer
            files={files}
            onFileClicked={onFileClicked}
            parentElementWidth={parentElementWidth}
            parentElementHeight={parentElementHeight}
          />
        )
      }
    }
  }

  return (
    <Box className={classNames(classes.root, isOpen && classes.openedRoot)} onClick={openHangingFolder}>
      <Box className={classes.hangingFolder}>
        <Box className={classes.headerWrapper}>
          <div ref={headerRef} className={classes.header}>
            <Box className={classes.titleIconWrapper}>
              <Icon style={{ marginRight: 10 }}>
                <HangingFolderIcon color={'white'} />
              </Icon>
              <Typography style={{ maxWidth: labelMaxWidth }} className={classes.name}>
                {label}
              </Typography>
            </Box>
            {isOpen && (
              <IconButton
                color={'secondary'}
                classes={{
                  colorPrimary: classes.closeButtonColorPrimary,
                  colorSecondary: classes.closeButtonColorSecondary,
                }}
                aria-label="more"
                size="small"
                onClick={closeHangingFolder}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </Box>
        <animated.div
          style={animatedBody}
          className={classNames(classes.body, isOpen, isLast && classes.bodyLastElement)}
        >
          <Box className={classes.countWrapper}>
            <Typography className={classes.count}>{getCountLabel()}</Typography>
          </Box>
          {renderBody()}
        </animated.div>
      </Box>
      <Box>{openedFile && <OpenedFile file={openedFile} onFileClosed={onFileClosed} />}</Box>
      <Box className={classes.navigation}>
        {isOpen && (
          <animated.div style={animatedNavigation}>
            <Navigation label={`close ${label}`} color={color} onClick={closeHangingFolder} />
          </animated.div>
        )}
      </Box>
    </Box>
  )
}

HangingFolder.defaultProps = {
  index: 0,
  color: colors.sugarLightGray,
  hangingFoldersCountFromParent: 0,
  label: 'hanging folder',
  drawerLabel: 'drawer',
  parentElementWidth: DEFAULT_VIEWPORT_WIDTH,
  parentElementHeight: DEFAULT_VIEWPORT_HEIGHT,
}

export default HangingFolder
