import React from 'react'
import { Box } from '@material-ui/core'
import { collapsedHangingFolderStyles, collapsedHangingFoldersContainerStyles } from './Styles'

const CollapsedHangingFolder = (props) => {
  const classes = collapsedHangingFolderStyles(props)

  return (
    <Box className={classes.root}>
      <Box className={classes.hangingFolder} />
    </Box>
  )
}

const CollapsedHangingFoldersContainer = (props) => {
  const { hangingFolders, limit } = props
  const classes = collapsedHangingFoldersContainerStyles(props)

  return (
    <Box className={classes.root}>
      <Box className={classes.component}>
        <Box className={classes.hangingFoldersWrapper}>
          {hangingFolders &&
            hangingFolders.length > 0 &&
            hangingFolders.slice(0, limit).map((hangingFolder, hangingFolderIndex) => {
              return (
                <Box key={`hangingFolder-preview-${hangingFolderIndex}`}>
                  <CollapsedHangingFolder index={hangingFolderIndex} color={hangingFolder.color} />
                </Box>
              )
            })}
        </Box>
      </Box>
    </Box>
  )
}

CollapsedHangingFoldersContainer.defaultProps = {
  hangingFolders: [],
  limit: 4,
}

export default CollapsedHangingFoldersContainer
