import React, { useRef } from 'react'
import ClosedDrawer from './ClosedDrawer'
import { Grid } from '@material-ui/core'
import { drawersContainerStyles } from './Styles'

const DrawersContainer = (props) => {
  const { drawers, ...otherProps } = props
  const classes = drawersContainerStyles(props)
  const ref = useRef()

  return (
    <Grid ref={ref} container direction={'column'} wrap={'nowrap'} className={classes.gridContainer}>
      {drawers.map((drawer, index) => {
        return (
          <ClosedDrawer
            key={`closed-drawer-${index}`}
            drawer={drawer}
            isLast={drawers.length !== index}
            {...otherProps}
          />
        )
      })}
    </Grid>
  )
}

export default DrawersContainer
